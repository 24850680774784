<app-container-component tituloPage="Usuario dados" subtituloPage="Dados">
    <div class="row">
        <div class="mb-3 col-12 col-md-6 col-lg-6 col-xxl-3">
            <label for="text" class="form-label m-0 col-form-label-sm">Nome Usuario:</label>
            <input type="text" [disabled]="controleEdicao"  class="form-control form-control-sm" [value]="this.usuario.nome">
        </div>
        <div class="mb-3 col-12 col-md-6 col-lg-6 col-xxl-3 ">
            <label for="text" class="form-label m-0 col-form-label-sm">Email:</label>
            <input type="text" [disabled]="controleEdicao"  class="form-control form-control-sm" [value]="this.usuario.sub">
        </div>
    </div>
</app-container-component>