<app-container-component tituloPage="MSA" subtituloPage="Dados do estudo">
    <app-loading [carregando]="carregando"></app-loading>
    <form class="form-group row d-flex justify-content-center formulario-produto col-12" [formGroup]="formularioMsa">
        <div class="mb-3 col-12 col-md-6 col-lg-4 col-xxl-4">
            <label class="form-label">Identificação do estudo:</label>
            <input type="text" formControlName="identificacao" placeholder="Identificação"
                class="form-control form-control-sm">
            <app-mensagem-errors [control]="obterValidacao('identificacao')"></app-mensagem-errors>
        </div>
        <div class="mb-3 col-12 col-md-6 col-lg-4 col-xxl-4">
            <label class="form-label">Analista:</label>
            <input type="text" formControlName="analista" placeholder="analista" class="form-control form-control-sm">
            <app-mensagem-errors [control]="obterValidacao('analista')"></app-mensagem-errors>
        </div>
        <div class="mb-3 col-12 col-md-6 col-lg-4 col-xxl-4">
            <label for="text" class="form-label">Instrumento:</label>
            <input type="text" formControlName="instrumento" placeholder="Instrumento"
                class="form-control form-control-sm">
            <app-mensagem-errors [control]="obterValidacao('instrumento')"></app-mensagem-errors>
        </div>
        <div class="mb-3 col-12">
            <label for="text" class="form-label">Procedimento operacional do estudo:</label>
            <textarea type="text" formControlName="procOperacional" class="form-control form-control-sm"
                (input)="autoAjuste($event)"></textarea>
            <app-mensagem-errors [control]="obterValidacao('procOperacional')"></app-mensagem-errors>
        </div>
        @if(this.msaModel.id && this.msaModel.pathImgGageLb){
        <div class="mb-3 col-12 col-md-6 col-lg-5 col-xxl-5 d-flex align-items-center flex-column">
            <figure class="figure d-flex flex-column align-items-center">
                <label for="text" class="form-label mb-2">Gráfico do Gage Study RR <i class="fa-solid fa-trash-can"
                        (click)="deletarArquivo(this.msaModel.imgGageLb,'GRAFICOGAGE')"></i></label>
                <img [src]="this.msaModel.pathImgGageLb" class="figure-img img-fluid rounded" alt="...">
            </figure>
        </div>
        }@else {
        <div class="mb-3 col-12 col-md-6 col-lg-5 col-xxl-5 d-flex align-items-center flex-column">
            <label for="text" class="form-label">Gráfico do Gage Study RR:</label>
            <app-input-imagens [id]="1" (emissaoLista)="receberImagemGageStudy($event)"></app-input-imagens>
        </div>
        }
        @if(this.msaModel.id && this.msaModel.pathImgTwayAnova){
        <div class="mb-3 col-12 col-md-6 col-lg-5 col-xxl-5 d-flex align-items-center flex-column">
            <figure class="figure d-flex flex-column align-items-center">
                <label for="text" class="form-label mb-2">Imagem do Two Way ANOVA <i class="fa-solid fa-trash-can"
                        (click)="deletarArquivo(this.msaModel.imgTwayAnova,'TWOWAYANOVA')"></i></label>
                <img [src]="this.msaModel.pathImgTwayAnova" class="figure-img img-fluid rounded" alt="...">
            </figure>
        </div>
        }@else {

        <div class="mb-3 col-12 col-md-6 col-lg-5 col-xxl-5 d-flex align-items-center flex-column">
            <label for="text" class="form-label">Imagem do Two Way ANOVA:</label>
            <app-input-imagens [id]="2" (emissaoLista)="receberImagemWayAnova($event)"></app-input-imagens>
        </div>
        }

        <div class="mb-3 col-12 col-md-6 col-lg-6 col-xxl-6">
            <label for="text" class="form-label">Análise da interação:</label>
            <div class="input-group">
                <select class="form-select form-select-sm" formControlName="grafGageRr">
                    <option disabled>Análise da interação</option>
                    <option>Aprovado</option>
                    <option>Reprovado</option>

                </select>
            </div>
            <app-mensagem-errors [control]="obterValidacao('grafGageRr')"></app-mensagem-errors>
        </div>
        <div class="mb-3 col-12 col-md-6 col-lg-6 col-xxl-6">
            <label for="text" class="form-label">Análise sem interação::</label>
            <div class="input-group">
                <select class="form-select form-select-sm" formControlName="anTolerancia">
                    <option disabled>Análise sem interação:</option>
                    <option>Aprovado</option>
                    <option>Reprovado</option>
                </select>
            </div>
            <app-mensagem-errors [control]="obterValidacao('anTolerancia')"></app-mensagem-errors>
        </div>
        @if(this.msaModel.id && this.msaModel.pathImgEstTolerancia){
        <div class="mb-3 col-12 d-flex align-items-center flex-column">
            <figure class="figure d-flex flex-column align-items-center">
                <label for="text" class="form-label">Imagem do estudo de tolerância <i class="fa-solid fa-trash-can"
                        (click)="deletarArquivo(this.msaModel.imgEstTolerancia,'TOLERANCIA')"></i></label>
                <img [src]="this.msaModel.pathImgEstTolerancia" class="figure-img img-fluid rounded" alt="...">
            </figure>
        </div>
        }@else {
        <div class="mb-3 col-12 col-md-12 col-lg-12 col-xxl-12 d-flex align-items-center flex-column">
            <label for="text" class="form-label mb-2">Imagem do estudo de tolerância:</label>
            <app-input-imagens [id]="3" (emissaoLista)="receberImagemEstudoTolerancia($event)"></app-input-imagens>
        </div>
        }
        <div class="mb-3 col-12 col-md-6 col-lg-4 col-xxl-4">
            <label for="text" class="form-label">Percentual de tolerância:</label>
            <input type="text" formControlName="ndc" placeholder=" % tolerância" class="form-control form-control-sm">
            <app-mensagem-errors [control]="obterValidacao('ndc')"></app-mensagem-errors>
        </div>
        <div class="mb-3 col-12 col-md-6 col-lg-4 col-xxl-4">
            <label for="text" class="form-label">Análise de tolerância:</label>
            <div class="input-group">
                <select class="form-select form-select-sm" formControlName="grafGageRr">
                    <option disabled>Análise de tolerância:</option>
                    <option>Adequado</option>
                    <option>Marginal</option>
                    <option>Inadequado</option>
                </select>
            </div>
            <app-mensagem-errors [control]="obterValidacao('grafGageRr')"></app-mensagem-errors>
        </div>
        <div class="mb-3 col-12 col-md-6 col-lg-4 col-xxl-4">
            <label for="text" class="form-label">Número de categorias distintas:</label>
            <div class="input-group">
                <select class="form-select form-select-sm" formControlName="anTolerancia">
                    <option disabled>Número de categorias distintas:</option>
                    <option>Aprovado</option>
                    <option>Reprovado</option>
                </select>
            </div>
            <app-mensagem-errors [control]="obterValidacao('anTolerancia')"></app-mensagem-errors>
        </div>
        @if(this.msaModel.id && this.msaModel.pathImgVies){
        <div class="mb-3 col-12 d-flex align-items-center flex-column">
            <figure class="figure d-flex flex-column align-items-center">
                <label for="text" class="form-label">Imagem do teste de linearidade e viés <i
                        class="fa-solid fa-trash-can"
                        (click)="deletarArquivo(this.msaModel.imgVies,'LINEARIDADE')"></i></label>
                <img [src]="this.msaModel.pathImgVies" class="figure-img img-fluid rounded"
                    alt="Imagem do teste de linearidade e viés">
            </figure>
        </div>
        }@else {
        <div class="mb-3 col-12 col-md-6 col-lg-12 col-xxl-12 d-flex align-items-center flex-column">
            <label for="text" class="form-label">Imagem do teste de linearidade e viés:</label>
            <app-input-imagens [id]="4" (emissaoLista)="receberImagemLinearidadeVies($event)"></app-input-imagens>
        </div>
        }
        <div class="mb-3 col-12 col-md-6 col-lg-4 col-xxl-4">
            <label for="text" class="form-label">Análise de linearidade:</label>
            <div class="input-group">
                <select class="form-select form-select-sm" formControlName="anLinearidade">
                    <option disabled>Análise de linearidade:</option>
                    <option>Aprovado</option>
                    <option>Reprovado</option>
                </select>
            </div>
            <app-mensagem-errors [control]="obterValidacao('anLinearidade')"></app-mensagem-errors>
        </div>
        <div class="mb-3 col-12 col-md-6 col-lg-4 col-xxl-4">
            <label for="text" class="form-label">Análise de viés:</label>
            <div class="input-group">
                <select class="form-select form-select-sm" formControlName="anVies">
                    <option disabled>Análise de viés:</option>
                    <option>Aprovado</option>
                    <option>Reprovado</option>
                </select>
            </div>
            <app-mensagem-errors [control]="obterValidacao('anVies')"></app-mensagem-errors>
        </div>
        <div class="mb-3 col-12 col-md-6 col-lg-4 col-xxl-4">
            <label for="text" class="form-label">Resultado do estudo:</label>
            <div class="input-group">
                <select class="form-select form-select-sm" formControlName="anSistema">
                    <option disabled>Resultado do estudo:</option>
                    <option>Aprovado</option>
                    <option>Reprovado</option>
                </select>
            </div>
            <app-mensagem-errors [control]="obterValidacao('anSistema')"></app-mensagem-errors>
        </div>
        <div class="mb-3 col-12">
            <label for="text" class="form-label">Observações:</label>
            <textarea type="text" formControlName="obs" class="form-control form-control-sm"
                (input)="autoAjuste($event)"></textarea>
            <app-mensagem-errors [control]="obterValidacao('obs')"></app-mensagem-errors>
        </div>
        @if(this.msaModel.id && this.msaModel.pathArquivoMpj){
        <div class="mb-3 col-12 col-md-6 col-lg-12 col-xxl-12 d-flex align-items-center flex-column">
            <a [href]="this.msaModel.pathArquivoMpj" target="_blank">
                Arquivo MSA
            </a>
            <div (click)="deletarArquivo(this.msaModel.arquivoMpj,'MJP')">
                <i class="fa-solid fa-trash-can"></i>
            </div>
        </div>
        }@else {
        <div class="mb-3 col-12 col-md-6 col-lg-12 col-xxl-12 d-flex align-items-center flex-column">
            <label for="text" class="form-label">Arquivo MPJ:</label>
            <app-input-imagens [id]="5" (emissaoLista)="receberImagemMpj($event)"></app-input-imagens>
        </div>
        }

        <div>
            @if(this.msaModel.id){
            <app-botao label="Editar" class="col-12 col-sm-3 col-md-4 col-lg-3" icon="icons8-disquete.png"
                status="btn-salvar" (click)="editar()"></app-botao>
            }@else{
            <app-botao label="Salvar" class="col-12 col-sm-3 col-md-4 col-lg-3" icon="icons8-disquete.png"
                status="btn-salvar" (click)="salvar()"></app-botao>
            }
        </div>
    </form>
</app-container-component>