import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import Swiper from 'swiper';
import { History, Pagination } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { Produto } from '../../../../model/engenharia/produto-model';
import { BookService } from '../../../../services/producao/book.service';
import { EngDispositivoPinturaService } from '../../../../services/engenharia/processo/eng-dispositivo-pintura.service';
import { DispositivoService } from '../../../../services/dispositivos/dispositivo.service';
import { SwiperComponentComponent } from "../swiperComponent/swiperComponent.component";

@Component({
  selector: 'app-dispositivo-de-pintura',
  standalone: true,
  imports: [CommonModule, FormsModule, SwiperComponentComponent],
  templateUrl: './dispositivo-de-pintura.component.html',
  styleUrl: './dispositivo-de-pintura.component.scss'
})
export class DispositivoDePinturaComponent implements OnInit {
  dispositivoDePintura: any = [];
  imagemNaoCadastrada1 = false;
  imagemNaoCadastrada2 = false;
  produto: Produto = new Produto();
  processoDoProduto: string[] = [];
  dados: any;
  dispositivoPinturaProcesso1: any;
  dispositivoPinturaProcesso2: any;
  dispositivo: any;
  imagens1: any = [];
  imagens2: any = [];
  tabAtiva = '';
  erro: string = "";
  @ViewChild('swiperDispositivoPintura1') swiperDispositivoPintura1!: ElementRef<HTMLElement>;
  @ViewChild('swiperDispositivoPintura2') swiperDispositivoPintura2!: ElementRef<HTMLElement>;
  swiper: Swiper | null = null;
  rotation: number = 0;

  constructor(
    private dispositivoEngService: EngDispositivoPinturaService,
    private dispositivoService: DispositivoService,
    private bookService: BookService,
    private cdr: ChangeDetectorRef,
  ) {

  }

  ngOnInit(): void {
    this.receberDadosDoProduto();
  }






  receberDadosDoProduto() {
    BookService.emissorProduto.subscribe({
      next: (res: any) => {
        this.produto = new Produto();
        this.dispositivoDePintura = [];
        this.tabAtiva = '';
        this.dispositivoPinturaProcesso1 = [];
        this.dispositivoPinturaProcesso2 = [];
        this.produto = res;
        if (this.produto.nomeProduto) {
          this.dados = this.bookService.verificarProcesso(this.produto.processo);
          this.consultarDispositivoDePinturaProcesso1Inicial(this.dados.numeroProcesso1);

        }
      },
    });
  }

  /**
   * Realiza a consulta do dispositivo de insenção do primeiro processo do produto
   * @param processo1 
   */
  consultarDispositivoDePinturaProcesso1Inicial(processo1: number) {
    this.imagemNaoCadastrada1 = false;
    this.imagens1 = [];
    this.dispositivoEngService.consultarEngDispositivoPinturaPorIdProcesso(this.produto.cdProduto, processo1).subscribe({
      next: (res) => {
        this.dispositivoPinturaProcesso1 = res[0];

      }, error: (e) => {
        this.consultarDispositivoDePinturaProcesso2(this.dados.numeroProcesso2);
        this.imagemNaoCadastrada1 = true;
      },
      complete: () => {
        this.definirTabAtiva();
        this.consultarDispositivoPorId();
        this.consultarDispositivoDePinturaProcesso2(this.dados.numeroProcesso2);
      },
    });
  }

  /**
   * Realiza a consulta do dispositivo de isenção do segundo processo
   * @param processo2 
   */
  consultarDispositivoDePinturaProcesso2(processo2: number) {
    this.imagemNaoCadastrada2 = false;
    this.imagens2 = [];
    this.dispositivoEngService.consultarEngDispositivoPinturaPorIdProcesso(this.produto.cdProduto, processo2).subscribe({
      next: (res) => {
        this.dispositivoPinturaProcesso2 = res[0];
      }, error: (e) => {
        this.imagemNaoCadastrada2 = true;
      }, complete: () => {
        this.definirTabAtiva();
        this.consultarDispositivoPorId();

      },
    });
  }

  consultarDispositivoDePinturaProcesso1Click(processo1: number) {
    this.imagemNaoCadastrada1 = false;
    this.imagens1 = [];
    this.dispositivoEngService.consultarEngDispositivoPinturaPorIdProcesso(this.produto.cdProduto, processo1).subscribe({
      next: (res) => {
        this.dispositivoPinturaProcesso1 = res[0];

      }, error: (e) => {
        this.imagemNaoCadastrada1 = true;
      }, complete: () => {
        this.definirTabAtiva();
        this.consultarDispositivoPorId();
      },
    });
  }

  /**
   * Realiza a consulta do dispositivo de isenção do segundo processo
   * @param processo2 
   */
  consultarDispositivoDePinturaProcesso2Click(processo2: number) {
    this.imagemNaoCadastrada2 = false;
    this.imagens2 = [];
    this.dispositivoEngService.consultarEngDispositivoPinturaPorIdProcesso(this.produto.cdProduto, processo2).subscribe({
      next: (res) => {
        this.dispositivoPinturaProcesso2 = res[0];
      }, error: (e) => {
        this.imagemNaoCadastrada2 = true;
      }, complete: () => {
        this.definirTabAtiva();
        this.consultarDispositivoPorId();

      },
    });
  }

  private definirTabAtiva() {
    if (this.dispositivoPinturaProcesso1) {
      this.tabAtiva = 'processo1';
      return
    } if (this.dispositivoPinturaProcesso2) {
      this.tabAtiva = 'processo2';
    }
  }


  public rodarImagem() {
    if (this.rotation >= 270) {
      this.rotation = 0;
    } else {
      this.rotation += 90
    }
  }

  consultarDispositivoPorId() {
    if (this.dispositivoPinturaProcesso1) {
      this.imagens1 = [];
      this.dispositivoService.consultarPorCdDispostivo(this.dispositivoPinturaProcesso1.cdDispositivo).subscribe({
        next: (res) => {
          this.dispositivo = res;
          this.imagens1 = res.imagens;
        }, error: (error) => {
          this.erro = error.error.mensagem;
        }, complete: () => {
          this.iniciarImagemCarregada();
        }
      });
    }

    if (this.dispositivoPinturaProcesso2) {
      this.imagens2 = [];
      this.dispositivoService.consultarPorCdDispostivo(this.dispositivoPinturaProcesso2.cdDispositivo).subscribe({
        next: (res) => {
          this.dispositivo = res;
          this.imagens2 = res.imagens;
        }, error: (error) => {
          this.erro = error.error.mensagem;
        }, complete: () => {
          this.iniciarImagemCarregada2();
        }
      });
    }
  }


  private iniciarImagemCarregada() {
    this.iniciarImagemCarregadaParaArray(this.imagens1);
  }

  private iniciarImagemCarregada2() {
    this.iniciarImagemCarregadaParaArray(this.imagens2);
  }

  private iniciarImagemCarregadaParaArray(array: any[]) {
    array.forEach((e: any) => {
      if (!e.imagemCarregada) {
        e.imagemCarregada = false;
      }
    });
  }


}
