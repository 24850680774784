import { ProdutoDetalheImagemService } from './../../../services/engenharia/produto/produto-detalhe-imagem.service';
import { ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { ProdutoDetalheService } from '../../../services/engenharia/produto/produto-detalhe.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DadosProdutoComponent } from '../book-componentes/dados-produto/dados-produto.component';
import { ImagensProdutoComponent } from '../book-componentes/imagens-produto/imagens-produto.component';
import { BookService } from '../../../services/producao/book.service';
import { PadraoEmbalagemComponent } from '../book-componentes/padrao-embalagem/padrao-embalagem.component';
import { PadraoMontagemComponent } from '../book-componentes/padrao-montagem/padrao-montagem.component';
import { DispositivoIsencaoComponent } from '../book-componentes/dispositivo-isencao/dispositivo-isencao.component';
import { PadraoIsencaoComponent } from '../book-componentes/padrao-isencao/padrao-isencao.component';
import { PadraoDefeitosComponent } from '../book-componentes/padrao-defeitos/padrao-defeitos.component';
import { PadraoRetrabalhoComponent } from '../book-componentes/padrao-retrabalho/padrao-retrabalho.component';
import { DeterminacaoProcessoComponent } from '../book-componentes/determinacao-processo/determinacao-processo.component';
import { ContainerComponentComponent } from '../../../componentes/container-component/container-component.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ModalConsultaComponent } from '../modal-consulta/modal-consulta.component';
import { SnackBarService } from '../../../utils/snack-bar.service';
import { DispositivoDePinturaComponent } from "../book-componentes/dispositivo-de-pintura/dispositivo-de-pintura.component";
import { CriterioDeInspecaoComponent } from "../book-componentes/criterio-de-inspecao/criterio-de-inspecao.component";
import { MedicaoCamadaComponent } from "../book-componentes/medicao-camada/medicao-camada.component";


@Component({
  selector: 'app-book-producao-visualizar',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    DadosProdutoComponent,
    ImagensProdutoComponent,
    PadraoEmbalagemComponent,
    PadraoMontagemComponent,
    DispositivoIsencaoComponent,
    PadraoIsencaoComponent,
    PadraoDefeitosComponent,
    PadraoRetrabalhoComponent,
    DeterminacaoProcessoComponent,
    ContainerComponentComponent,
    MatDialogModule,
    DispositivoDePinturaComponent,
    CriterioDeInspecaoComponent,
    MedicaoCamadaComponent
  ],
  templateUrl: './book-producao-visualizar.component.html',
  styleUrls: ['./book-producao-visualizar.component.scss']
})
export class BookProducaoVisualizarComponent implements OnInit,OnDestroy {
  nomeProduto: string = '';
  prodNaoEncontrado: boolean = false;
  tituloPagina: string = 'BOOK DO PRODUTO';
  subtituloPagina: string = 'INFORMAÇÕES DO PRODUTO'
  cdProduto: string = '';
  produtos: any[] = [];
  paginaAtual: number = 0;
  totalTamanho: number = 0;
  quantidadesPorPagina: any[] = [3, 8, 12, 16];
  totalPorPagina: number = this.quantidadesPorPagina[0];

  constructor(
    private produtoService: ProdutoDetalheService,
    private dialog: MatDialog,
    private snackbarService: SnackBarService,
    private imagemService: ProdutoDetalheImagemService,
    private cdr: ChangeDetectorRef,
  ) { }
  ngOnDestroy(): void {
    localStorage.removeItem("valorPesquisadoProducao");
  }

  ngOnInit() {
    this.nomeProduto = localStorage.getItem("valorPesquisadoProducao") ? localStorage.getItem("valorPesquisadoProducao")! : '';
    if (this.nomeProduto.trim() !== '') {
      this.consultarProdutos()
    }
  }

  public consultaProdutoPorNome() {
    if (this.nomeProduto.trim() !== '') {
      this.prodNaoEncontrado = false;
      let dialogRef = this.dialog.open(ModalConsultaComponent, {
        data: { 'nomeProduto': this.nomeProduto },
        height: 'auto',             // Altura automática conforme o conteúdo
        maxHeight: '80vh',          // Altura máxima do modal 80% da altura da tela
        position: { 'top': '20px' },
        disableClose: true
      });
      // Escutar quando o modal for fechado e capturar o valor de retorno
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          BookService.emissorProduto.emit(result);
          console.log(result)
          localStorage.setItem("valorPesquisadoProducao", this.nomeProduto.trim());
        } else {
          console.log('Nenhum produto foi selecionado');
        }
      });
    } else {
      this.snackbarService.openSnackBar('Digite o código ou nome do produto!', 'my-snack-bar-erro')
    }
  }

  removerMensagem() {
    setTimeout(() => {
      this.prodNaoEncontrado = false;
    }, 5000);
  }


  public salvarValor() {
    localStorage.setItem("valorPesquisadoProducao", this.nomeProduto.trim());
  }







  public consultarProdutos() {
    this.produtoService.consultarProduto(parseInt(this.nomeProduto)).subscribe({
      next: (res) => {
        BookService.emissorProduto.emit(res);
        console.log(res);
      }, error: (e) => {

      }
    });
  }



}
