import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { Produto } from '../../../../model/engenharia/produto-model';
import { BookService } from '../../../../services/producao/book.service';
import { MedicaoCamadaService } from '../../../../services/inspecao-equipamento/medicaoCamada.service';
import { EngMedicaoCamadaService } from '../../../../services/engenharia/processo/eng-medicao-camada.service';
import { SwiperComponentComponent } from "../swiperComponent/swiperComponent.component";

@Component({
  selector: 'app-medicao-camada',
  standalone: true,
  imports: [CommonModule, SwiperComponentComponent],
  templateUrl: './medicao-camada.component.html',
  styleUrl: './medicao-camada.component.scss'
})
export class MedicaoCamadaComponent implements OnInit {


  medicaoCamadaProcesso1: any = [];
  medicaoCamadaProcesso2: any = [];
  imagemNaoCadastrada1 = false;
  imagemNaoCadastrada2 = false;
  produto: Produto = new Produto();
  processoDoProduto: string[] = [];
  dados: any;
  tabAtiva: string = '';
  @ViewChild("swiperMedicaoCamadaProcesso1") swiperMedicaoCamadaProcesso1!: ElementRef;
  @ViewChild("swiperMedicaoCamadaProcesso2") swiperMedicaoCamadaProcesso2!: ElementRef;
  swiper!: Swiper;

  constructor(
    private medicaoCamadaService: EngMedicaoCamadaService,
    private bookService: BookService,
    private cdr: ChangeDetectorRef,
  ) {

  }

  ngOnInit(): void {
    this.receberDadosDoProduto();
  }

  receberDadosDoProduto() {
    BookService.emissorProduto.subscribe({
      next: (res: any) => {
        this.produto = new Produto();
        this.medicaoCamadaProcesso1 = [];
        this.medicaoCamadaProcesso2 = [];
        this.tabAtiva = '';
        this.produto = res;
        if (this.produto.nomeProduto) {

          this.dados = this.bookService.verificarProcesso(this.produto.processo);
          this.consultarmedicaoCamadaProcesso1(this.dados.numeroProcesso1);
          this.consultarmedicaoCamadaProcesso2(this.dados.numeroProcesso2);
        }
      },
      error: (e: any) => {

      }
    });
  }

  consultarmedicaoCamadaProcesso1(processo: number) {
    this.imagemNaoCadastrada1 = false;
    this.medicaoCamadaProcesso1 = [];
    this.medicaoCamadaService.consultarMedicaoDeCamada(this.produto.cdProduto, processo).subscribe({
      next: (res) => {
        this.medicaoCamadaProcesso1 = res;
      }, error: (e) => {
        this.imagemNaoCadastrada1 = true;
        this.consultarmedicaoCamadaProcesso2(this.dados.numeroProcesso2);
      }, complete: () => {
        this.definirTabAtiva();
        this.iniciarImagemCarregada();
        this.consultarmedicaoCamadaProcesso2(this.dados.numeroProcesso2);
      },

    });
  }

  consultarmedicaoCamadaProcesso2(processo: number) {
    this.imagemNaoCadastrada2 = false;
    this.medicaoCamadaProcesso2 = [];
    this.medicaoCamadaService.consultarMedicaoDeCamada(this.produto.cdProduto, processo).subscribe({
      next: (res) => {
        this.medicaoCamadaProcesso2 = res;
      }, error: (e) => {
        this.imagemNaoCadastrada2 = true;
      }, complete: () => {
        this.definirTabAtiva();
        this.iniciarImagemCarregada2();
      },
    });
  }

  private definirTabAtiva() {
    if (this.medicaoCamadaProcesso1) {
      this.tabAtiva = 'processo1';
      return;
    } if (this.medicaoCamadaProcesso2) {
      this.tabAtiva = 'processo2';
    }
  }


  private iniciarImagemCarregada() {
    this.iniciarImagemCarregadaParaArray(this.medicaoCamadaProcesso1);
  }

  private iniciarImagemCarregada2() {
    this.iniciarImagemCarregadaParaArray(this.medicaoCamadaProcesso2);
  }

  private iniciarImagemCarregadaParaArray(array: any[]) {
    array.forEach((e: any) => {
      if (!e.imagemCarregada) {
        e.imagemCarregada = false;
      }
    });
  }

}
