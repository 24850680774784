import { MatDialog } from '@angular/material/dialog';
import { EmisorEventoGeralService } from './../../../../utils/emisorEventoGeral.service';
import { SnackBarService } from './../../../../utils/snack-bar.service';
import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, OnChanges, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { ProdutoDetalheService } from '../../../../services/engenharia/produto/produto-detalhe.service';
import { Produto } from '../../../../model/engenharia/produto-model';
import { ModalConsultaComponent } from '../../../producao/modal-consulta/modal-consulta.component';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { environment } from '../../../../../environments/environment';


@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive, RouterOutlet, FormsModule, MatSidenavModule, MatTooltipModule],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss'
})
export class MenuComponent implements OnInit, OnChanges, AfterViewInit {
  produto: any = '';
  produtoConsultado: Produto = new Produto();
  urlImagem: any;
  listaDeImagens: any[] = [];
  listaProcessos: any[] = [];
  listaMsa: any[] = [];
  linksEngenharia: any[] = [];
  listaOpcaoExtra: any[] = [];
  listaCriterioAceita: any[] = [];
  listaNorma: any[] = [];
  listaCliente: any[] = [];
  listaLicaoAprendida: any[] = [];
  listaRetrabalho: any[] = [];
  produtoSupersmart: boolean = false;
  open: boolean = true;
  mostrarMenu = false;
  menuFixo = true;
  menusVisiveis: { [key: string]: boolean } = {};
  link = `${environment.url}/`;
  rotaAtual:any;

  @ViewChild('valor') valorInput: ElementRef | undefined
  @ViewChild('drawer') drawer!: MatDrawer;
  @Output() acao = new EventEmitter<any>();

  constructor(
    private routerLink: Router,
    private activeRouter: ActivatedRoute,
    private produtoDetalheService: ProdutoDetalheService,
    // private imagemService: ProdutoDetalheImagemService,
    // private processosEngenhariaRotaService: RotaEngenhariaService,
    // private processosProcessoRotaService: RotaProcessosService,
    // private processosOpcaoExtraService: RotaOpcaoExtraService,
    // private processosCriterioAceitacao: RotaCriterioAceitacaoService,
    // private processosCriterioCliente: RotaCriterioClienteService,
    // private processosNorma: RotaNormaService,
    // private processosLicaoAprendida: RotaLicaoAprendidasService,
    // private processosRetabalho: RotaRetrabalhoService,
    private snackBarService: SnackBarService,
    private emisorEventoGeralService: EmisorEventoGeralService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
  ) {

  }
  ngAfterViewInit(): void {
    this.restaurarEstadoMenu();
    this.verificarSeEhMobile();
    this.verificarRotaAtual();
    this.cdr.detectChanges();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.verificarMudancaCodigo();
  }
  ngOnInit(): void {
    this.verificarIdNaRota();
    this.verificarMudancaCodigo();
    this.pegarCdProduto();
    this.mostrarMenu = false;

  }


  public verificarMudancaCodigo() {
    this.verificarSeProdutoESupersmart();
  }

  public verificarIdNaRota(){
    this.activeRouter.firstChild?.params.subscribe((res: any) => {
      this.produto = res.cdProduto;
      if (!this.produto) {
        this.produto = "";
      } else {
        this.consultarProdutoSelecionado();
      }
    });
  }

  public verificarRotaAtual(){
    this.rotaAtual = this.routerLink.url
  }

  public verificarSeProdutoESupersmart() {
    EmisorEventoGeralService.produtoSupersmart.subscribe(res => {
      this.produto = res;
      if (this.produto) {
        this.mostrarMenu = true;
        this.verificarIdNaRota();
      }
    });
  }

  isMobile = window.innerWidth < 1000;

  @HostListener('window:resize', [])
  onResize() {
    this.isMobile = window.innerWidth < 1000;
    if (this.isMobile) {
      this.menuFixo = false;
    }

  }

  public verificarSeEhMobile() {
    this.isMobile = window.innerWidth <= 1000;
    if (this.isMobile) {
      sessionStorage.setItem("menuFixado", "false");
      this.open = true;
      this.menuFixo = false;
    }
  }

  // toggleDrawer(drawer: MatDrawer) {
  //   if (this.isMobile) {
  //     drawer.toggle();
  //     this.mudarIcone();
  //   }
  // }

  // public mudarOpcaoMenu(event: MouseEvent) {
  //   if (this.produto) {
  //     const elementoClicado = event.target as HTMLElement;
  //     const menu = document.querySelector(".menu-content");
  //     if (menu) {
  //       menu?.classList.add("submenu-active");
  //       elementoClicado.classList.add("show-submenu");

  //       // Salvar o estado no localStorage
  //       localStorage.setItem('clickedElementId', elementoClicado.id);
  //       localStorage.setItem('menuState', JSON.stringify({
  //         submenuActive: menu.classList.contains('submenu-active'),
  //         showSubmenu: elementoClicado.classList.contains('show-submenu')
  //       }));
  //     } else {
  //       console.log('Menu não encontrado');
  //     }
  //   } else {
  //     this.snackBarService.openSnackBar("Codigo de peça invalido", "my-snack-bar-erro");
  //   }
  // }

  // public voltarMenu(event: MouseEvent) {
  //   const selecionarMenuAberto = document.querySelector(".show-submenu") as HTMLElement;
  //   const menu = document.querySelector(".menu-content") as HTMLElement;

  //   if (menu) {
  //     // console.log('Removendo classe submenu-active do menu');
  //     menu.classList.remove("submenu-active");
  //   } else {
  //     console.log('Menu não encontrado');
  //   }

  //   if (selecionarMenuAberto) {
  //     // console.log('Removendo classe show-submenu do elemento clicado');
  //     selecionarMenuAberto.classList.remove("show-submenu");
  //   } else {
  //     console.log('Elemento clicado não encontrado');
  //   }

  //   // Atualizar o estado no localStorage
  //   // console.log('Atualizando estado no localStorage');
  //   localStorage.removeItem('clickedElementId');
  //   localStorage.setItem('menuState', JSON.stringify({
  //     submenuActive: false,
  //     showSubmenu: false
  //   }));

  //   menu.classList.remove("submenu-active")
  //   selecionarMenuAberto!.classList.remove("show-submenu")
  // }


  private restaurarEstadoMenu() {
    this.menuFixo = JSON.parse(sessionStorage.getItem("menuFixado")! || 'false');
    if (this.drawer) {
      if (this.menuFixo) {
        this.drawer.open();
        this.open = false;
      }
    }
  }


  public consultarProduto(event?: KeyboardEvent) {
    if (!event || event.key === "Enter") {
      const valor = (event?.target as HTMLInputElement)?.value.trim();
      if (!valor && !this.produto.trim()) {
        this.routerLink.navigate([`engenharia/produto-detalhe`]);// Navega para a página de detalhes do produto se nenhum valor for fornecido
        this.listaDeImagens = [];
        this.urlImagem = "";
        this.mostrarMenu = false;
        return;
      }
      let dialogRef = this.dialog.open(ModalConsultaComponent, {
        data: { 'nomeProduto': valor },
        height: 'auto',             // Altura automática conforme o conteúdo
        maxHeight: '80vh',          // Altura máxima do modal 80% da altura da tela
        position: { 'top': '20px' },
        disableClose: true
      });
      // Escutar quando o modal for fechado e capturar o valor de retorno
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.produto = result.cdProduto;
          this.consultarProdutoSelecionado();
        } else {
          console.log('Nenhum produto foi selecionado');
        }
      });
    }
  }

  public fecharMenu() {
    this.acao.emit(true);
  }


  public consultarProdutoSelecionado() {
    this.produtoDetalheService.consultarProduto(this.produto).subscribe({
      next: (res) => {
        this.produtoConsultado = res;
      }, error: () => {
        this.routerLink.navigate([`engenharia/produto-detalhe`]);
        this.snackBarService.openSnackBar("Peça não encontrado no supersmart nem no book", 'my-snack-bar-erro');

      }, complete: () => {
        this.pegarCdProduto();
        this.getRotaFilhaAtualComParametros();
        this.mostrarMenu = true;
      }
    });
  }


  public getRotaFilhaAtualComParametros() {
    const rotaFilhaAtual = this.activeRouter.firstChild;
    if (rotaFilhaAtual) {
      let novaRota = `engenharia/${rotaFilhaAtual.snapshot.url[0].path}/${this.produtoConsultado.cdProduto}`;
      this.routerLink.navigate([novaRota]);
      this.emisorEventoGeralService.emitirNovaConsulta(true);
    } else {
      this.routerLink.navigate([`engenharia/produto-detalhe`]);
    }
  }


  public mudarIcone() {
    if (!this.menuFixo) {
      this.open = !this.open
    }
  }

  abrirMenu(menu: string) {
    if (this.mostrarMenu) {
      this.menusVisiveis[menu] = !this.menusVisiveis[menu];
    } else {
      if (menu == "msa") {
        this.menusVisiveis[menu] = !this.menusVisiveis[menu];
      }else{
        this.snackBarService.openSnackBar("Digite um codigo de um produto", "my-snack-bar-warning");
      }
    }

  }

  fixarMenu(): void {
    this.menuFixo = !this.menuFixo; // Alterna o estado do menu fixo
    sessionStorage.setItem("menuFixado", this.menuFixo.toString());
  }

  clicarMenu(drawer: any): void {
    if (!this.menuFixo) {
      drawer.toggle(); // Só executa o toggle se o menu não estiver fixado
    }
  }

  clicarFechar(drawer: any): void {
    if (!this.menuFixo) {
      drawer.toggle(); // Só executa o toggle se o menu não estiver fixado
    } else {
      this.snackBarService.openSnackBar("Para recolher o menu você precisa clicar no icone desfixar menu", "my-snack-bar-warning")
    }
  }


  public pegarCdProduto() {
    this.linksEngenharia = this.getMenuItensEngenharia(this.produto);
    this.listaProcessos = this.getMenuItensProcessos(this.produto)
    this.listaOpcaoExtra = this.getMenuItensOpcaoExtra(this.produto);
    this.listaCriterioAceita = this.getMenuItensCriterioAceitacao(this.produto);
    this.listaNorma = this.getMenuItensNorma(this.produto);
    this.listaCliente = this.getMenuItensCriterioCliente(this.produto);
    this.listaLicaoAprendida = this.getMenuItensLicaoAprendida(this.produto);
    this.listaRetrabalho = this.getMenuItensRetrabalho(this.produto);
    this.listaMsa = this.getMenuItensMsa()
  }

  public getMenuItensEngenharia(cdProduto?: string) {
    return [
      { label: 'Dados do produto', link: ['produto-detalhe', cdProduto!] },
      { label: 'Imagens', link: ['produto-detalhe-imagens', cdProduto!] },
      { label: 'Desenhos e documentos', link: ['produto-detalhe-desenho', cdProduto!] },
      { label: 'Aplicação do produto', link: ['produto-detalhe-aplicacao', cdProduto!] },
      { label: 'Condição recebimento', link: ['produto-detalhe-codicao-recebimento', cdProduto!] },
      { label: 'Critério de aparência', link: ['produto-detalhe-criterios-aparencia', cdProduto!] },
      { label: 'Peças similares', link: ['produto-detalhe-pecas-similares', cdProduto!] },
      { label: 'Relátorio de ensaios', link: ['produto-detalhe-relatorio-ensaio', cdProduto!] },
      { label: 'Alteração de projeto', link: ['produto-detalhe-registro-alteracao-projeto', cdProduto!] },
      { label: 'Acompanhamento desenvolvimento', link: ['produto-detalhe-acompanhamento-desenvolvimento', cdProduto!] },
      { label: 'Plano de controle', link: ['produto-detalhe-plano-de-controle', cdProduto!] },
      // { label: 'APROVAÇÕES', link: ['produto-detalhe-aprovacoes',cdProduto!] },
    ];
  }

  public getMenuItensProcessos(cdProduto: string) {
    return [
      { label: 'DETERMINAÇÃO', link: ['eng-produto-registrar-determinacao', cdProduto] },
    ];
  }

  public getMenuItensNorma(cdProduto: string) {
    return [
      { label: 'NORMA', link: ['norma', cdProduto] },
    ];
  }


  public getMenuItensOpcaoExtra(cdProduto: string) {
    return [
      { label: 'OPÇÃO EXTRA', link: ['opcao-extra', cdProduto] },
    ];
  }

  public getMenuItensCriterioAceitacao(cdProduto: string) {
    return [
      { label: 'Criterio de aceitacao', link: ['criterio-aceitacao', cdProduto] },
    ];
  }

  public getMenuItensCriterioCliente(cdProduto: string) {
    return [
      { label: 'CRITÉRIO CLIENTE', link: ['criterio-cliente', cdProduto] },
    ];
  }

  public getMenuItensLicaoAprendida(cdProduto: string) {
    return [
      { label: 'LIÇÃO APRENDIDA', link: ['licao-aprendida', cdProduto] },
    ];
  }

  public getMenuItensRetrabalho(cdProduto: string) {
    return [
      { label: 'RETRABALHO', link: ['retrabalho', cdProduto] },
    ];
  }
  public getMenuItensMsa() {
    return [
      { label: 'MSA - Dados contínuos', link: ['msa'] },
      { label: 'MSA - Registrar', link: ['msa-registrar'] },
    ];
  }



}
